import React from "react"
import { Link } from "gatsby"
import { FiMapPin, FiLock, FiTag, FiAward, FiDatabase, FiCompass } from "react-icons/fi";
import { AiOutlineTool } from "react-icons/ai";
import Layout from "../components/layout"
import SEO from "../components/seo"

import BloqueContacto from "../components/bloque-contacto"
import BloqueDondeEstamos from "../components/bloque-donde-estamos"
import imgDestacada from '../images/dms-home-destacado.svg'
import { icon_oficina, icon_experiencia, icon_friend } from '../images/historia';
import { adel, jordi, toni } from '../images/quienes-somos';
import { ibm, clouding, cisco, arsys, veeam, microsoft, vmware, onretrieval } from '../images/partners';
import { manuel_cantueso, jordi_serra, pepe_galindo, quote } from '../images/testimoniales';

const IndexPage = () => (
    <Layout className="page-home">
        <SEO 
            title="Mantenimiento Informático Barcelona, IBM Informix, Planificación de personal y Soporte" 
            description="Mantenimiento Informático Barcelona, Servicios Informáticos, Outsourcing, Planificación de personal, Soporte, IBM Informix, Hosting"
            />

        <section className="bloque bloque-featured">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1>El soporte informático que te dejará tranquilo</h1>
                        <h2>Soporte informático rápido y fiable para pymes de Barcelona ¿Hablamos?</h2>
                        <Link className="btn btn-accent" title="Contacta con DMS" to="/contacto">Contactar</Link>
                    </div>
                    <div className="col-md-6">
                        <img src={imgDestacada} alt="Soporte informático que te dejará tranquilo" />
                    </div>
                </div>
            </div>

            <div className="super-powers">
                <div className="container">
                    <div className="row">
                        <article className="col-md-6 col-lg-3">
                            <span className="icono-bola icono-power">
                                <FiMapPin />
                            </span>
                            <h3>Estamos en barcelona</h3>
                            <p>Nunca más tendrás que esperar</p>
                        </article>
                        <article className="col-md-6 col-lg-3">
                            <span className="icono-bola icono-power">
                                <FiLock />
                            </span>
                            <h3>Ciberseguridad</h3>
                            <p>Nos aseguramos de que tus datos estén seguros</p>
                        </article>
                        <article className="col-md-6 col-lg-3">
                            <span className="icono-bola icono-power">
                                <FiTag />
                            </span>
                            <h3>Desde 190€/mes</h3>
                            <p>Precios flexibles adaptados a tu tamaño</p>
                        </article>
                        <article className="col-md-6 col-lg-3">
                            <span className="icono-bola icono-power">
                                <FiAward />
                            </span>
                            <h3>Fiabilidad</h3>
                            <p>Sólo nos gusta el trabajo bien hecho</p>
                        </article>																		
                    </div>
                </div>
            </div>  	
        </section>


        <section className="bloque bloque-historia">
            <div className="container">
                <div className="bloque-header">
                    <h2 className="title">Nuestra historia</h2>
                    <p className="info">13 años en el sector TI ofreciendo servicios de Soporte Informático, IBM Informix y Workforce Management</p>
                </div>

                <ul className="historia-content">
                    <li>
                        <span className="col-1 col-sm-1 col-lg-1 historia-icon">
                            <img className="icon_oficina" src={icon_oficina} alt="oficina" />
                        </span>
                        <span className="col-11 col-sm-11 col-lg-11 historia-info">
                            DMS nace en 2007 con la misión ayudar a las Pymes a gestionar mejor sus negocios gracias a los Sistemas Informáticos y Soluciones de Software.
                        </span>
                    </li>
                    <li>
                        <span className="col-1 col-sm-1 col-lg-1 historia-icon">
                            <img className="icon_experiencia" src={icon_experiencia} alt="experiencia" />
                        </span>
                        <span className="col-11 col-sm-11 col-lg-11 historia-info">
                            10 años de experiencia en la provisión de servicios y la implantación de proyectos informáticos nos ha permitido generar un vasto conocimiento técnico y un know-how diferencial en la gestión de servicios para empresas.
                        </span>
                    </li>
                    <li>
                        <span className="col-1 col-sm-1 col-lg-1 historia-icon">
                            <img className="icon_friend" src={icon_friend} alt="friend" />
                        </span>
                        <span className="col-11 col-sm-11 col-lg-11 historia-info">
                            Nuestra cultura gira entorno a la creación de valor para nuestros clientes enfocándonos sólo en lo que somos expertos para construir relaciones a largo plazo.
                        </span>
                    </li>
                </ul>
            </div>
        </section>


        <section className="bloque-quienes">
            <div className="container">
                <div className="bloque-header">
                    <h2 className="title">Quienes somos</h2>
                    <p className="info">Somos muy técnicos pero también somos emprendedores y sabemos ponernos en la piel de nuestros clientes para centrarnos en lo estratégico.
                        Tenemos mentalidad Lean Thinking por lo que tenemos presente siempre la calidad y la mejora continua en todo lo que hacemos.</p>
                </div>

                <div className="row row-employees justify-content-center">

                    <div className="employee col-md-6 col-lg-4">
                        <figure className="employee-img">
                            <img src={toni} alt="Antoni Guitart"/>
                        </figure>
                        <div className="employee-info">
                            <span className="name">ANTONI GUITART</span>
                            <span className="role">CEO</span>
                        </div>
                    </div>

                    <div className="employee col-md-6 col-lg-4">
                        <figure className="employee-img">
                            <img src={jordi} alt="Jordi Arnau"/>
                        </figure>
                        <div className="employee-info">
                            <span className="name">JORDI ARNAU</span>
                            <span className="role">DIRECTOR COMERCIAL</span>
                        </div>
                    </div>

                    <div className="employee col-md-6 col-lg-4">
                        <figure className="employee-img">
                            <img src={adel} alt="Adel Pujadas"/>
                        </figure>
                        <div className="employee-info">
                            <span className="name">ADEL PUJADAS</span>
                            <span className="role">CTO</span>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="bloque bloque-servicios">
            <div className="container">

                <div className="bloque-header">
                    <h2 className="title">Nuestros servicios</h2>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <article className="servicio">
                            <div className="servicio-container">
                                <span className="icono-bola">
                                    <AiOutlineTool />
                                </span>
                                <h3>Mantenimiento Informático</h3>
                                <p>Nuestro centro de Soporte en Barcelona ofrece el servicio de Mantenimiento Informático Flexible que te ayuda a reducir tus costes Informáticos con una cuota que se adapta a la carga de trabajo</p>
                            </div>
                            <div className="btn-container">
                                <Link className="btn btn-accent" to="/soporte-informatico" title="Soporte informático">Más información</Link>
                            </div>
                        </article>																	
                    </div>	
                    <div className="col-12 col-md-6 col-lg-4">
                        <article className="servicio">
                            <div className="servicio-container">
                                <span className="icono-bola">
                                    <FiDatabase />
                                </span>
                                <h3>IBM Informix</h3>
                                <p>Servicios de consultoría, soporte y migración a nuevas versiones. También hacemos desarrollos en 4GL y Genero</p>
                            </div>
                            <div className="btn-container">
                                <Link className="btn btn-accent" to="/informix" title="Informix">Más información</Link>
                            </div>
                        </article>																	
                    </div>			
                    <div className="col-12 col-md-6 col-lg-4">
                        <article className="servicio">
                            <div className="servicio-container">
                                <span className="icono-bola">
                                    <FiCompass />
                                </span>
                                <h3>Planificación Inteligente</h3>
                                <p>SP-Expert es la aplicación de Worfkforce Management que te permite planificar tu personal de forma inteligente reduciendo costes y mejorando tu eficiencia</p>
                            </div>
                            <div className="btn-container">
                                <Link className="btn btn-accent" to="/gestion-tiempo" title="Gestión de tiempo">Más información</Link>
                            </div>
                        </article>																	
                    </div>																					
                </div>			
            </div>
        </section>				


        <section className="bloque bloque-partners">
            <div className="container">

                <div className="bloque-header">
                    <h2 className="title">Nuestros partners</h2>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-6 partner">
                        <img src={ibm} alt="ibm"/>
                    </div>

                    <div className="col-lg-3 col-md-6 partner">
                        <img src={clouding} alt="clouding"/>
                    </div>

                    <div className="col-lg-3 col-md-6 partner">
                        <img src={cisco} alt="cisco"/>
                    </div>

                    <div className="col-lg-3 col-md-6 partner">
                        <img src={arsys} alt="arsys"/>
                    </div>

                    <div className="col-lg-3 col-md-6 partner">
                        <img src={veeam} alt="veeam"/>
                    </div>

                    <div className="col-lg-3 col-md-6 partner">
                        <img src={microsoft} alt="microsoft"/>
                    </div>

                    <div className="col-lg-3 col-md-6 partner">
                        <img src={vmware} alt="vmware"/>
                    </div>

                    <div className="col-lg-3 col-md-6 partner">
                        <img src={onretrieval} alt="onretrieval"/>
                    </div>
                </div>

            </div>

        </section>		


        <section className="bloque-testimoniales">
            <div className="container">
                <div className="bloque-header">
                    <h2 className="title">232 pymes de Barcelona felices</h2>
                </div>

                <div className="testimoniales-perfiles">
                    <article className="perfil">
                        <div className="perfil-header">
                            <div className="perfil-header-info">
                                <h3 className="name">Manuel Cantueso</h3>
                                <p className="role">Socio de Bellavista</p>
                            </div>
                            <img src={manuel_cantueso} className="avatar" alt="Foto Manuel Cantueso" />
                        </div>
                        <div className="perfil-info">
                            <div className="box">
                                <img src={quote}  className="quotes" alt="quote" />
                                <p className="box-info">
                                    “Antoni y su equipo ofrecen un servicio de alta calidad, ofreciendo soluciones de bajo coste, y siempre con una respuesta muy rápida, lo que es especialmente importante en el tema informático.”
                                </p>
                            </div>
                        </div>
                    </article>

                    <article className="perfil">
                        <div className="perfil-header">
                            <div className="perfil-header-info">
                                <h3 className="name">Jordi Serra</h3>
                                <p className="role">Plant Manager en Isofel</p>
                            </div>
                            <img src={jordi_serra} className="avatar" alt="Foto Jordi Serra" />
                        </div>
                        <div className="perfil-info">
                            <div className="box">
                                <img src={quote} className="quotes" alt="quote" />
                                <p className="box-info">
                                    “DMS lleva más de 6 años administrando nuestra infraestructura informática y puedo decir que son proactivos e insistentes y siempre consiguen ponerlo todo en orden.”
                                </p>
                            </div>
                        </div>
                    </article>

                    <article className="perfil">
                        <div className="perfil-header">
                            <div className="perfil-header-info">
                                <h3 className="name">Pepe Galindo</h3>
                                <p className="role">Administración en Sivis</p>
                            </div>
                            <img src={pepe_galindo} className="avatar" alt="Foto Pepe Galindo" />
                        </div>
                        <div className="perfil-info">
                            <div className="box">
                                <img src={quote} className="quotes" alt="quote" />
                                <p className="box-info">
                                    "DMS siempre nos ha prestado el asesoramiento y la resolución de problemas de una manera próxima y ágil, dado el conocimiento que tienen de nuestros sistemas y equipos. La disponibilidad de sus técnicos nos aporta tranquilidad ante problemas informáticos y orientación en la toma de decisiones en temas complejos."
                                </p>
                            </div>
                        </div>
                    </article>

                </div>
            </div>
        </section>        	


        <BloqueContacto />

        <BloqueDondeEstamos />

    </Layout>
)

export default IndexPage