import React, {useState} from 'react'
import {navigate} from 'gatsby'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const BloqueContacto = (props) => {
	const [state, setState] = useState({});
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    
	const handleChange = (e) => {
	    setState({ ...state, [e.target.name]: e.target.value });
	}

	const handleServerResponse = (ok, msg, form) => {
	  setServerState({
	    submitting: false,
	    status: { ok, msg }
	  });
	  if (ok) {
	    form.reset();
	  }
	};      

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;

		fetch('/', {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		  body: encode({
		    'form-name': form.getAttribute('name'),
		    ...state,
		  }),
		})
		.then((response) => {
		    console.log(response);
		    if (response.ok === true) {
		        //handleServerResponse(true, "Mensaje enviado correctamente. En breve nos pondremos en contacto contigo.", form);
                //window.history.pushState('Contacto','Formulario enviado','/contacto-ok');
                navigate('/contacto-ok');
		    }
		    else{
		        handleServerResponse(false, "Se ha producido un error enviando el formulario. Inténtalo de nuevo o contacta con el administrador del sitio web.", form);
		    }
		})
		.catch(e => {
		    console.log(e);
		    handleServerResponse(false, "Se ha producido un error enviando el formulario. Inténtalo de nuevo o contacta con el administrador del sitio web.", form);
		});  

	}

    return(
        <section className="bloque-page-contacto">
            <div className="contacto-content">

                {!props.isPage &&
                    (<div className="bloque-header-form">
                        <h2 className="title">Contacto</h2>
                        <p className="text">
                            Escríbenos o llama al <span>932 479 959</span> y te responderemos rápidamente
                        </p>
                    </div>)
                }

                <form 
                    method="post"
                    name="contactar"
                    className="contacto-form container"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                    >

                    <h3>Envíanos un mensaje</h3>
                    <input type="hidden" name="form-name" value="contactar" />
                    <p className="hidden">
                        <label>No rellenar si eres humano: <input name="bot-field" /></label>
                    </p>

                    <div className="row">
                        <div className="col-sm-6 form-group">
                            <label htmlFor="nombre">Nombre</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nombre"
                                name="nombre"
                                placeholder="Escribe tu nombre"
                                required
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-sm-6 form-group">
                            <label htmlFor="empresa">Empresa</label>
                            <input type="text"
                                className="form-control"
                                id="empresa"
                                name="empresa"
                                placeholder="Nombre de la empresa"
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-sm-6 form-group">
                            <label htmlFor="email">Correo electrónico</label>
                            <input type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                required
                                placeholder="Escribe tu correo electrónico"
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-sm-6 form-group">
                            <label htmlFor="telefono">Teléfono</label>
                            <input type="text"
                                className="form-control"
                                id="telefono"
                                name="telefono"
                                placeholder="Número de teléfono"
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="form-group">  
                        <label htmlFor="mensaje">Mensaje</label>
                        <textarea 
                            className="form-control" 
                            placeholder="Explícanos el motivo de tu mensaje para que podamos ayudarte :)" 
                            name="mensaje" 
                            id="mensaje" 
                            onChange={handleChange}
                            required
                            rows="5">
                            </textarea>
                    </div>

                    <div className="btn-group">  
                        <button type="submit" className="btn btn-accent col-1">Enviar</button>
                    </div>

                    {serverState.status && (
                        <p className={!serverState.status.ok ? "errorMsg" : "okMsg"}>
                            {serverState.status.msg}
                        </p>
                    )}                      
                </form>
            </div>
        </section>    

    )
}

export default BloqueContacto


