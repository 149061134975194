import React from "react";
import { FiFacebook, FiTwitter, FiLinkedin } from "react-icons/fi";
import { google_maps, at_sign, map_pin_1, phone } from '../images/donde-estamos';

const BloqueDondeEstamos = () => (
    <section className="bloque bloque-donde">
        <div className="container">

            <div className="bloque-header">
                <h2 className="title">Dónde estamos</h2>
            </div>

            <div className="donde-container row">
                <div className="donde-map col-lg-8">
                    <img src={google_maps} alt="map" />
                </div>

                <div className="donde-info col-lg-4">
                    <h3 className="donde-header">VENTURAE CAP S.L.</h3>

                    <ul className="donde-list">
                        <li>
                            <span className="donde-icon">
                                <img src={map_pin_1} alt="map" />
                            </span>
                            <div className="donde-text">
                                <span>Calle Tuset 8 3º 4ª</span>
                                <span>08006 Barcelona (España).</span>
                            </div>
                        </li>
                        <li>
                            <span className="donde-icon">
                                <img src={phone} alt="phone" />
                            </span>
                            <div className="donde-text">
                                <span>+34 93 247 99 59</span>
                                <span>(de 9h a 18h de lunes a viernes)</span>
                            </div>
                        </li>
                        <li>
                            <span className="donde-icon">
                                <img src={at_sign} alt="sign" />
                            </span>
                            <div className="donde-text">
                                <span>info@dms.cat</span>
                            </div>
                        </li>
                    </ul>

                    <ul className="donde-social">
                        <li>
                            <a href="https://www.facebook.com/DMS-Services-SL-202263486495751/" target="_blank" rel="noopener noreferrer" className="icon-container">
                                <FiFacebook aria-label="icon facebook" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/_dmsservices" rel="noopener noreferrer" target="_blank" className="icon-container">
                                <FiTwitter aria-label="icon twitter" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/dms-services-s-l-/" target="_blank" rel="noopener noreferrer" className="icon-container">
                                <FiLinkedin aria-label="icon linkedin" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
)

export default BloqueDondeEstamos;
